import React from "react";
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import {Button, FormControl, Form, Modal} from 'react-bootstrap';
import { createEmailContact } from "../../request"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class MailingForm extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            email: "",
            honeyPot: null,
            tags: ["tag_newsletter"],
            hasEmailError: false,
            success: false,
            isProcessing: false,
            showModal: false,
            fieldsError: false,
            serverError: false,
            finished: false
        }
    }

    handleInputChange = (e) => {
        if (e && e.target && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }

        this.setState({
            hasEmailError: false
        });
    }

    handleHoneyPotChange = event => {
        const target = event.target;
        const value = target.value;

        this.setState({
            honeyPot: value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isProcessing: true,
            finished: false,
            hasEmailError: false,
            fieldsError: false,
            serverError: false
        });

        if (!this.state.email) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasEmailError: true
            });


        } else if (!this.validateEmail(this.state.email)) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasEmailError: true
            });

        } else {

            if (!this.state.honeyPot) {

                let language = "en", region = "US";
                if (navigator && navigator.language) {
                    let navigatorLanguage = navigator.language.split("-");
                    if (navigatorLanguage && navigatorLanguage.length === 2){
                        language = navigatorLanguage[0].toLowerCase();
                        region = navigatorLanguage[1].toUpperCase();
                    }
                }

                createEmailContact({email: this.state.email, tags: this.state.tags, language: language, region: region}).then(() => {

                    this.setState({
                        isProcessing: false,
                        showModal: true,
                        email: "",
                        finished: true,
                        serverError: false
                    });

                }).catch((e) => {

                    this.setState({
                        isProcessing: false,
                        showModal: true,
                        finished: false,
                        serverError: true
                    });

                });

            } else {

                this.setState({
                    isProcessing: false,
                    showModal: true,
                    finished: false,
                    serverError: true
                });

            }

        }
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        return (
            <div className={`mailing-form`}>
                <div className={`title`}><FormattedMessage id="components.mailingForm.title" /></div>
                <div className={`text`} dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: "components.mailingForm.text" }) }}></div>
                <div className={`form`}>
                    <Form className={`form-area`} onSubmit={this.handleSubmit}>
                        <div className="form-field-group">
                            <Form.Label className="form-label"><FormattedMessage id="components.mailingForm.email.label" /></Form.Label>
                            <FormControl type="text" value={this.state.email} name={`email`} className="form-field" onChange={this.handleInputChange} />

                            <div style={{position: "absolute", left: "-9999px", overflow: "hidden"}} aria-hidden="true">
                                <input className="form-field" placeholder={this.props.intl.formatMessage({ id: "components.mailingForm.email.placeholder" })} type="text" onChange={this.handleHoneyPotChange} />
                            </div>

                            {this.state.hasEmailError === true &&
                            <>
                                <div className={`feedback-text error`}><FormattedMessage id="components.mailingForm.email.error" /></div>
                                <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                            </>
                            }
                        </div>
                        <Button className={`form-button`} variant="secondary" type="submit" block disabled={this.state.isProcessing}><FormattedMessage id="components.mailingForm.button" /></Button>
                    </Form>

                    <Modal show={this.state.showModal} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.fieldsError === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-triangle" />
                                    <FormattedMessage id="components.mailingForm.alerts.titles.warning" />
                                </>
                                }
                                {this.state.serverError === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-circle" />
                                    <FormattedMessage id="components.mailingForm.alerts.titles.error" />
                                </>
                                }
                                {this.state.finished === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="check-circle" />
                                    <FormattedMessage id="components.mailingForm.alerts.titles.success" />
                                </>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={`text-center m-3`}>
                                {this.state.fieldsError === true &&
                                <FormattedMessage id="components.mailingForm.alerts.error" />
                                }
                                {this.state.serverError === true &&
                                <FormattedMessage id="components.mailingForm.alerts.serverError" />
                                }
                                {this.state.finished === true &&
                                <FormattedMessage id="components.mailingForm.alerts.success" />
                                }
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        )
    }
}

export default injectIntl(MailingForm)
