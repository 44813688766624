import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroPagesCenter from "../../components/heroes/hero-pages-center"
import MailingListData from "../../content/mailing/index.json"
import TickerData from "../../content/home/ticker.json";
import Testimonials from "../../components/home/testimonials";
import ClientsTicker from "../../components/misc/clients-ticker";
import MailingForm from "../../components/forms/mailing-form"

const MailingListPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={MailingListData[intl.locale].title} description={MailingListData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`primary`} content={MailingListData[intl.locale]} />
        <div className={`mailing-list-page`}>
            <div className={`top-overflow-wrapper`}>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <div className={`mailing-list-form-box`}>
                                <MailingForm />
                            </div>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    <Testimonials />
                    <ClientsTicker content={TickerData[intl.locale]} />
                </Container>
            </div>
        </div>
    </Layout>
)

export default injectIntl(MailingListPage)
